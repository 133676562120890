import Certification from "./Certification";

const Certifications = () => {
    return (
        <div className="w-100 h-100 mt-lg-4 mb-auto pt-lg-5">
            <span className="d-flex mb-4 mb-xl-5 border-top border-2 border-md-0 pt-4 mt-4 mt-md-2 mb-md-2 mb-lg-4 mb-xl-5 pt-md-1 justify-content-center">
                <h3 className="d-flex align-items-center  fs-lg-heading fs-xl-heading" style={{width: 'fit-content'}}><p className="me-3 me-xl-4 p-2 px-3  headingDecorationOne fs-4 fs-lg-heading fs-xl-heading rounded">{`<>`}</p>CERTIFICATIONS <p className="p-2 fs-lg-heading fs-xl-heading  headingDecorationOne fs-4 fs-md-6 rounded ms-3 ms-xl-4">{`</>`}</p></h3>
            </span>
            {[{src: 'https://brunonavarrete.github.io/assets/img/treehouse.png', title:'Web Development Techdegree'}].map( cert => (
                <Certification key={cert} src={cert.src} title={cert.title}/>
            ))}
        </div>
    )
}

export default Certifications;