import { Client  , Databases} from 'appwrite'

export const client = new Client();

const appwriteConfig = {
    endpoint: 'https://cloud.appwrite.io/v1',
    projectId: '6734e51b0039994e5ea4',
    databaseId: '6734e5b10030d882ebd9',
    projectsCollectionId: '6734e5c0001c7b4d781b'

}

client
    .setEndpoint(appwriteConfig.endpoint)
    .setProject(appwriteConfig.projectId)

const databases = new Databases(client);

export const getAllProjects = async () => {
    try {
        const result = await databases.listDocuments(
            appwriteConfig.databaseId,
            appwriteConfig.projectsCollectionId,
        )
        return result.documents;
    } catch (error) {
        throw error;
    }
}