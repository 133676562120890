import { getAllProjects } from "../../appwrite/appwrite";
import useAppwrite from "../../appwrite/useAppwrite";
import icons from "../../assets/icons";
import About from "./About";
import Certifications from "./certifications/Certifications";
import Projects from "./projects/Projects";

const Home = () => {
    const { data:projects } = useAppwrite(() => getAllProjects());
    return (
        <div className="w-100 h-100 d-flex justify-content-center pt-1 px-4 mx-auto row">
            <div className="d-flex flex-column col-12 col-md-4 mb-5 pb-2 mb-md-0" id="Home">
                <div className="w-100 mx-auto d-flex">
                    <img src={icons.IntroMessage} className="col-10 col-md-12 col-lg-9 col-xl-6 mx-auto" alt=""   />
                </div>
                <div className="mx-auto" style={{display: 'grid'}}>
                    <img src={icons.IndexStyle} alt="" className="mx-auto col-10 col-lg-8 col-xl-6" style={{gridRow: '1/2', gridColumn: '1/2'}}/>
                    <img src={icons.Profile} alt="" className=" rounded mx-auto col-5 col-lg-4  col-xl-3 my-auto" style={{gridRow: '1/2', gridColumn: '1/2'}}/>
                </div>
                <img src={icons.DevGreeting} alt=""  className="mx-auto col-11 col-lg-10 col-xl-8 "/>
                <div className="d-flex align-items-center justify-content-center">
                    <div className="mx-2">
                        <div className="border-bottom border-2 text-center w-50 pb-2 mx-auto" >
                            <img src={icons.SocialsIcon}  alt=""  className="homeIconSocials" style={{width: '4rem'}} />
                        </div>
                        <div className="d-flex align-items-center w-100 mt-2 justify-content-center">
                            {['https://www.facebook.com/profile.php?id=61559539658958', 'https://x.com/BrandonDurand16'].map( social => (
                                <button key={social} className="bg-white border-0 mx-1 iconHover iconSocials fa-bounce" style={{width: "4rem"}}><a href={social}><img src={social.includes('facebook') ? icons.FaceBookIcon:icons.TwitterIcon} alt="" className="w-100" /></a></button>
                            ))}
                        </div>
                    </div>
                    <a href="https://play.google.com/store/apps/details?id=com.ptco.aora&hl=en-US&ah=hNil1abhS600pczlYjOJYRHu4SM&pli=1" className="d-flex flex-column mx-2 flex-md-row align-items-center justify-content-end mobileAppIconHome">
                        <h5 className="d-flex align-items-center me-0 me-md-3 mb-3 mb-md-0 text-color-2"><b className="mb-0 me-1 text-color-1">Try</b> Mobile <b className="mb-0 ms-1 text-black">App</b></h5>
                        <img src={icons.logo} alt=""  className="w-100 footer-lg-logo  footer-xl-logo border rounded-4"/>
                    </a>
                </div>
            </div>
            <div className="col-12  col-md-8 d-flex flex-column justify-lg-content-start pb-2 pb-md-0 mt-lg-5 " id="About">
                <About />
            </div>
            <div className="col-12  col-md-12 mb-5 mb-md-0 d-flex flex-column justify-content-center h-100 h-md-90 h-md" id="Projects">
                <Projects projects={projects}/>
            </div>
            <div className="col-12  col-md-12 mt-lg-5 d-flex flex-column justify-content-center h-100 h-md" id="Certifications">
                <Certifications />
            </div>
        </div>
    )
}

export default Home;