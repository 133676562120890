import icons from "../../assets/icons";

const About = () => {
    return (
        <div className="w-100 d-flex flex-column align-items-center justify-content-center mb-4">
            <h3 className="d-flex align-items-center justify-content-center mb-2 mb-xl-5 border-top border-2 fs-lg-heading fs-xl-heading  pt-4 pt-md-4 mt-0 mb-lg-4 w-100 border-md-0 " ><p className="me-3 ms-xl-4 p-2 px-3   headingDecorationOne fs-4 fs-lg-heading fs-xl-heading rounded">{`<>`}</p>ABOUT <p className="p-2  headingDecorationOne fs-4 fs-lg-heading  fs-xl-heading rounded ms-3 ms-xl-4">{`</>`}</p></h3>
            <div className="row w-100">
                <div className="col-12 me-md-auto  mb-3 mb-md-0 mb-xl-4 d-flex flex-md-row flex-column align-items-center">
                    <img src={icons.AboutBanner} alt=""  className="col-5 col-md-4" />
                    <p className="fira-sans-thin-italic fs-lg fs-xl aboutBio">
                        Waddup! I'm Brandon, a <b>Full Stack Web Developer</b> , that lives & breathes tech & code. I specialize in <b>React.Js</b> & <b>Vue.Js</b>  <b>Front-End technologies</b> and <b>Frameworks</b>, in combination with <b>Express.Js</b> & <b>Node.Js</b> as the <b>Backend</b>.
                    </p>
                </div>
                <div className="col-12 col-md-6 mx-md-auto me-lg-0 justify-content-center d-flex pt-2 my-md-2">
                    <div className="d-flex flex-column text-center">
                        <h6 className="p-2  pb-1 rounded bg-1 mb-2 fs-lg-sub-heading fs-xl-heading">FRONTEND</h6>
                        {['React.Js', 'React Native', 'Vue.Js', 'Javascript', 'HTML5', 'CSS', 'Boostrap', 'TailwindCSS', 'Figma'].map( tech => (
                            <b key={tech} className="fira-sans-thin-italic-bold fs-lg fs-xl">{tech}</b>
                        ))}
                    </div>
                    <div className="mx-3 d-flex flex-column text-center">
                        <h6 className="p-2  pb-1 rounded bg-2 mb-2 fs-lg-sub-heading fs-xl-heading">BACKEND</h6>
                        {['Node.Js', 'Express.Js', 'MongoDB', 'Javascript', 'SQL', 'Sequelize', 'Appwrite'].map( tech => (
                            <b key={tech} className="fira-sans-thin-italic-bold fs-lg fs-xl">{tech}</b>
                        ))}
                    </div>
                    <div className="d-flex flex-column text-center">
                        <h6 className="p-2  pb-1 rounded bg-3 mb-2 fs-lg-sub-heading fs-xl-heading">UTILITIES</h6>
                        {['Git', 'VS Code', 'npm', 'Expo'].map( tech => (
                            <b key={tech} className="fira-sans-thin-italic-bold fs-lg fs-xl">{tech}</b>
                        ))}
                    </div>
                </div>
                <div className="col-12 col-md-8 col-lg-6  mx-auto pt-2">
                    <h5 className="border-bottom border-2 pb-1 mb-3 mb-md-2 pb-xl-4 mb-xl-4 w-50 mx-auto  text-center fs-xl-heading">More</h5>
                    <div className="d-flex align-items-center justify-content-center">
                        <button className="d-flex align-items-center justify-content-center onHover rounded bg-2 mx-2 py-1 py-xl-2 px-xl-4 fa-bounce delay-1" ><a href="https://pdfupload.io/docs/f24f3572" className="text-black fira-sans-semibold fs-xl " style={{textDecoration: 'none'}}> Resume <img src={icons.ResumeIcon} alt=""  className="w-25 aboutMoreIcons" /></a></button>
                        <button className="d-flex align-items-center justify-content-center onHover rounded bg-3 mx-2 py-1 py-xl-2 px-xl-4 fa-bounce delay-2 " ><a href="https://www.linkedin.com/in/brandon-durand-15950a301/" className=" text-black fira-sans-semibold fs-xl" style={{textDecoration: 'none' }}> LinkedIn <img src={icons.LinkedInIcon} alt=""  className="w-25 aboutMoreIcons"  /></a></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;