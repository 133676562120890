import Web_Development_Certificate from '../../../assets/Web_Development_Certificate.jpg'
const Certification = ({src, title}) => {
    return (
        <div className="d-md-flex justify-content-center h-75 w-100 mx-lg-auto">
            <div className="w-100 w-lg-35 pt-2 pt-md-0 h-50 h-md-75 ">
                <img src={Web_Development_Certificate} className="w-100 h-100" alt=''/>
            </div>
            <h1 className="d-flex flex-column text-center align-items-center w-75 w-lg-25 mx-auto mx-md-0 w-md-25 ms-4  ms-md-5 mt-4 mt-md-5 text-black"><img src={src} className="w-25 mb-3" alt='' /> {title}</h1>
        </div>
    )
}

export default Certification; 