import { useState } from "react";
import icons from "../../../assets/icons";

const Project = ({src, title, type, stack, url, logo, github}) => {
    const [loaded, setLoaded] = useState(false)
    setTimeout(() => {
        setLoaded(true);
    }, 850);
    return (
        <div className="d-flex h-100 row mx-2">
            <div className={`${loaded ? null:'d-none'} col-10 mx-auto mx-md-0 col-md-4 ms-xl-auto col-xl-3 p-3 fa-loadIn fa-beat`} style={{maxWidth: "400px", minHeight: "330px"}}>
                <a href={url} className={`d-grid  ${src && src.includes('dtQ9MxdR') ? 'border-0':'border border-2-alt'} rounded onHover  p-1 h-100`} style={{textDecoration: 'none'}}>
                    <img src={src} alt=""  className={`rounded  ${src && src.includes('dtQ9MxdR') ? 'w-50 mx-auto':'w-100 h-100'}`} style={{gridColumn: "1/2", gridRow: "1/2"}}/>
                    <p className="button  rounded p-2 fs-5 mx-auto my-auto" style={{gridColumn: "1/2", gridRow: "1/2"}}>Visit</p>
                </a>
            </div>
            <div className={`${loaded ? null:'d-none'} d-flex col-12 mx-auto ms-xl-0   col-md-8 row`}>
                <div className="col-12 col-md-6 d-flex flex-column mx-auto mx-md-0 mt-md-2 text-center justify-center align-items-center fa-beat delay-1">
                    <h3 className="mt-2 fs-2 projectTitle text-black">
                        <img src={logo} alt=""  className="w-25 me-3"/>
                        {title}
                    </h3>
                    <span className="d-flex align-items-center ms-2 mt-3">
                        <p className="p-2 rounded border text-center fs-8 fs-lg-sub-heading fs-xl">{type}</p>
                    </span>
                    <a href={github} className="d-flex items-center onHover justify-content-center mt-4 bg-white border-0" >
                        <img src={icons.GitHub} className="" style={{width: "60px"}}/>
                        <h3 className="bg-2 text-white mt-2 ms-3 p-2 pb-0 h-75 rounded">GitHub</h3>
                    </a>
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center px-0 mx-auto  mt-2 pt-3 col-12 col-md-6 fa-beat delay-2" style={{height: 'fit-content'}}>
                    {stack && stack.map( tech => (
                        <img key={tech} src={icons[tech]} className="p-2 icons-sm  icons-md icons-lg rounded text-center" style={{height: 'fit-content'}} alt={tech}/>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Project;