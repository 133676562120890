import { useState } from "react";
import icons from "../../assets/icons";

const Footer = () => {
    const [options, setOptions] = useState(false)
    return (
        <footer className="d-flex justify-content-center align-items-center w-100 mt-auto footer-md-h" style={{height: '30%'}}>
            <nav className="w-100 d-flex align-items-center"> 
                <ul className={`d-flex flex-column flex-md-row align-items-center justify-content-evenly w-100 px-2 ps-2`}>
                    <button onClick={() => setOptions(pre => !pre)} className={`d-none d-md-flex align-items-center p-2 p-lg-1 p-xl-2 border-3 ${!options ? '':' text-white bg-dark'}  rounded text-black fira-sans-semibold me-xl-4`}><i className={`fa-solid ${!options ? 'fa-caret-down':'fa-caret-up text-white'} me-md-2  fs-4`}></i><p className={`d-none d-md-block fs-xl ${!options ? '':' text-white'}`}>More</p></button>
                    <div className={`d-flex align-items-center justify-content-evenly justify-content-md-start w-100 ms-md-3 ${options ? 'd-none':''}`}>
                        <li className="bg-1 p-2 p-lg-1  p-xl-2 rounded "><a href="#Home" className="text-black fira-sans-semibold">HOME</a></li>
                        <li className="bg-2 p-2 p-lg-1 p-xl-2 rounded mx-md-3"><a href="#About" className="text-black fira-sans-semibold">ABOUT</a></li>
                        <li className="bg-3 p-2 p-lg-1 p-xl-2 rounded"><a href="#Projects" className="text-black fira-sans-semibold">PROJECTS</a></li>
                        <li className="bg-4 p-2 p-lg-1 p-xl-2 rounded ms-md-3"><a href="#Certifications" className="text-black fira-sans-semibold">CERTIFICATIONS</a></li>
                    </div>
                    <div className={`${!options ? 'd-none':null} mx-auto`}>
                        <p className="d-flex">&copy; <small className="ms-1 fira-sans-semibold">Copyright of DTCO</small></p>
                    </div>
                    <div className={`d-md-none mx-auto mt-2 mb-3`}>
                        <p className="d-flex" >&copy; <small style={{fontSize:'12px' }} className="ms-1 fira-sans-semibold">Copyright of DTCO</small></p>
                    </div>
                </ul>
            </nav>
            <a href="https://play.google.com/store/apps/details?id=com.ptco.aora&hl=en-US&ah=hNil1abhS600pczlYjOJYRHu4SM&pli=1" className="d-flex align-items-center justify-content-end pe-5 mb-5 pb-4 mobileAppIconFooter">
                <h5 className="d-flex align-items-center me-3 mb-0 text-color-2"><b className="mb-0 me-1">Try</b> Mobile <b className="mb-0 ms-1 text-black">App</b></h5>
                <img src={icons.logo} alt=""  className="w-100 footer-lg-logo  footer-xl-logo border rounded-4"/>
            </a>
        </footer>
    )
}

export default Footer;